import React from "react";
import Table from "../Table/Table";

const Plant = () => {
  const data = [
    {
      name: "Issue 1",
      defectType: "Bug",
      assigned: "Alice",
      status: "Pending",
      priority: "Critical",
    },
    {
      name: "Issue 2",
      defectType: "Feature",
      assigned: "Bob",
      status: "Completed",
      priority: "Warning",
    },
    {
      name: "Issue 3",
      defectType: "Improvement",
      assigned: "Charlie",
      status: "Not Started",
      priority: "Manageable",
    },
    {
      name: "Issue 4",
      defectType: "Bug",
      assigned: "Dave",
      status: "Pending",
      priority: "Not Set",
    },
  ];
  return (
    <div>
      <Table data={data} />
    </div>
  );
};

export default Plant;
