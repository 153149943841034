import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const SideDrawer = ({ onClose, details }) => {
  //   const [carouselImages, setCarouselImages] = useState([]);
  const carouselImages = [
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CustomButton />,
    prevArrow: <CustomButton />,
  };
  return (
    <>
      <div className="fixed inset-0 overflow-hidden z-50 flex justify-end">
        <div className="w-1/3 bg-gray-200 p-4 h-full transform transition-transform duration-300 translate-x-0">
          {/* Close button */}
          <button
            className="absolute top-4 z-50 right-4 text-gray-600"
            onClick={onClose}
          >
            <svg
              className="w-6 h-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
          {/* Content */}
          <div className="h-full flex flex-col items-center">
            <div className="mt-8 mb-8 w-1/2">
              {carouselImages.length > 0 ? (
                <Slider {...settings}>
                  {carouselImages.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={`Slide ${index}`}
                        className="w-full"
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                "No Images Availabe"
              )}
            </div>
            <div>
              <pre>Name: {details.name}</pre>
              <pre>Minimum Temperature: 63.1</pre>
              <pre>Maximum Temperature: 52.1</pre>
              <pre>Temperature Difference: 11</pre>
              <pre>Priority: {details.priority}</pre>
              <pre>Status: {details.status}</pre>
              <pre>Defect Type: {details.defectType}</pre>
              <pre>Assigned to: {details.assigned}</pre>
            </div>
            {/* <pre>Timestamp: {details.timestamp}</pre> */}

            {/* Tabular Data
            <div>
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {tabularData.map((data, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">{data.name}</td>
                      <td className="border px-4 py-2">{data.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 transition-opacity"></div>
    </>
  );
};
const CustomButton = () => {
  return <div className="none"></div>;
};
export default SideDrawer;
