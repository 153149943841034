import React from "react";

const Card = ({ image, heading, defects, onClick }) => {
  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105 border-2 border-[#1870be]"
      onClick={onClick}
    >
      <img src={image} alt={heading} className="w-full h-48 object-cover" />
      <div>
        <hr className="h-px bg-[#1870be] border-none" />
      </div>
      <div className="p-4">
        <h3 className="text-[#1870be] text-lg font-bold">{heading}</h3>
        <p
          className={
            defects
              ? defects > 2
                ? "text-red-500"
                : "text-yellow-500"
              : "text-green-500"
          }
        >
          {defects
            ? "Number of Identified Defects: " + defects
            : "Everything's alright"}
        </p>
      </div>
    </div>
  );
};

export default Card;
