import AWS from "aws-sdk";

const fetchS3File = async (bucket, key) => {
  const s3 = new AWS.S3();
  const params = {
    Bucket: bucket,
    Key: key,
  };

  try {
    const data = await s3.getObject(params).promise();
    const fileUrl = URL.createObjectURL(new Blob([data.Body]));
    return fileUrl;
  } catch (error) {
    console.error("Error fetching file from S3", error);
    throw error;
  }
};

export default fetchS3File;
