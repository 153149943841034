import React, { useState } from "react";
import productsImg1 from "../../assets/productsImg1.png";
import productsImg2 from "../../assets/productsImg2.png";
import productsImg3 from "../../assets/productsImg3.png";
import productsImg4 from "../../assets/productsImg4.png";
import productsVideo from "../../assets/productsVideo.mp4";

const Products = () => {
    return (
        <div>
            <div className="bg-white p-4 flex justify-center h-auto">
                <div className="bg-white p-6 m-4 w-full h-auto flex flex-col md:flex-row items-center md:items-stretch">
                    <div className="md:w-1/2 order-2 md:order-1 p-4 flex flex-col justify-center">
                        <h2 className="text-[#DCB075] font-bold text-3xl mb-4">
                            Introducing Your AI Inspection Assistant{" "}
                        </h2>
                        <p className="text-gray-700 text-xl w-4/5">
                            The TerraWise Rover can operate completely autonomously 8 hours a
                            day, 365 days a year. It even charges itself! Equipped with a
                            Radiometric Thermal camera and a visual RGB camera, along with AI
                            analytics, our robot is the perfect inspection solution for
                            utility solar farms. Although compact and agile, it is built to
                            handle rugged terrain and tall grass.
                        </p>
                    </div>
                    <div className="md:w-1/2 order-1 md:order-2 flex items-center justify-center">
                        <img
                            src={productsImg1}
                            alt="Title 1"
                            className="h-3/4 object-cover"
                        />
                    </div>
                </div>
            </div>


            <div className="bg-[#445064] px-96 py-20 w-full flex flex-col items-center">
                <div className="w-[1200px] flex flex-col ">
                    <div className=" flex justify-center items-center align-middle">
                        <video
                            autoPlay
                            loop
                            muted
                            width="600px"
                            height="300px"
                        >
                            <source src={productsVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <img src={productsImg2} alt="Sample" width="600px" height="300px" />
                    </div>
                    <p className="text-white mt-6 text-justify text-xl">
                        Our Robot has been trained to perform combiner box thermography using
                        stop and go autonomy, and catch MC4 connector defects, panel
                        micro-cracks, and wire Junction box issues. But our modular analytics
                        software enables us to customize what our robot looks for based on
                        your specific needs.
                    </p>
                </div>
            </div>

            <div className="bg-white p-4 flex justify-center h-auto">
                <div className="bg-white p-6 m-4 w-full h-auto flex flex-col-reverse md:flex-row-reverse items-center md:items-stretch">
                    <div className="md:w-1/2 order-1 md:order-2 flex items-center justify-center">
                        <img
                            src={productsImg3}
                            alt="Title 1"
                            style={{ height: "400px", width: "300px" }}
                        />
                        <img
                            src={productsImg4}
                            alt="Title 1"
                            style={{ height: "400px", width: "300px" }}
                        />
                    </div>
                    <div className="md:w-1/2 order-2 md:order-1 p-4 flex flex-col justify-center">
                        <p className="text-gray-700 text-xl w-4/5">
                            Our solution comes with an easy to use app which includes GPS based mission planning, so you can ask your robot assistant to perform targeted inspections. Our powerful software platform comes with cutting-edge data analytics and visualisation tools to make your on-site repairs easy and efficient.
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center p-3 bg-black text-white">
                <p>© Copyright 2024 TerraWise, Inc. All Right Reserved</p>
            </div>
        </div>
    );
};

export default Products;
