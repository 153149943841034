import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.terra-wise.com/api/",
  headers: {
    "Content-Type": "application/json",
    // Add any additional headers here, such as authorization tokens
  },
});

export default instance;
