import React from "react";
import bgImage from "../../assets/bgImage3.jpg";
import intro from "../../assets/intro.mp4";
import DownArrow from "../DownArrow/DownArrow";
const HomePage = () => {
  return (
    <>
      {/* <div className="m-auto  w-1/2 bg-white    flex flex-col">
        <img className="p-8 rounded-t-lg" src={logo} alt="product image" />
      </div> */}
      <div className="flex flex-col items-center justify-center">
        <img src={bgImage} className="h-screen w-full -mt-16" alt="bgImage" />
        <div className="absolute right-0 bottom-0 text-6xl font-normal w-[30rem] mb-14 text-white">
          The Robotics Revolution has reached Solar
        </div>
        <DownArrow />
      </div>
      <div className="pt-16 pb-28 bg-[#2C2F2D] flex items-center flex-col gap-12 m-auto">
        <p className="text-[#DCB075] font-bold text-3xl">
          Intelligent Robots to Maximise Your Efficiency
        </p>
        <p className="text-white w-[40rem] text-center text-2xl">
          TerraWise has developed an autonomous rover to revolutionize
          operations & maintenance in solar. Designed to complement your
          workforce, our fast, agile, and precise robots work tirelessly to
          detect, predict, and prevent defects so your plant can maximize its
          yield
        </p>
        <video
          width="750"
          height="375"
          controls
          loop
          autoPlay
          className="rounded-md"
        >
          <source src={intro} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="flex flex-col items-center justify-center p-3 bg-black text-white">
        <p>© Copyright 2024 TerraWise, Inc. All Right Reserved</p>
      </div>
      <DownArrow />
    </>
  );
};

export default HomePage;
