import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "../../axiosConfig";
import fetchS3File from "../../s3-fetch";
import configureAWS from "../../aws-config";

configureAWS();

const SideDrawer = ({ onClose, details }) => {
  const [imageURLs, setImageURLs] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const [loading, setLoading] = useState(false);
  //   const tabularData = [
  //     { name: "Item 1", description: "Description 1" },
  //     { name: "Item 2", description: "Description 2" },
  //     { name: "Item 3", description: "Description 3" },
  //     { name: "Item 4", description: "Description 4" },
  //   ];
  // const getImageIds = async (token) => {
  //   try {
  //     setLoading(true);
  //     const { data } = await axios.get("geojson/images?name=" + details?.name, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     // s3://earthsense-solarbot-data/collection-170524_104244/thermal_1.jpg
  //     console.log(data);
  //     setImageURLs(data || []);
  //     const fetchImages = async () => {
  //       const urls = await Promise.all(
  //         imageURLs.map(async (image) => {
  //           const key = image.cloud_path.split(
  //             "s3://earthsense-solarbot-data/"
  //           )[1];
  //           const url = await fetchS3File("earthsense-solarbot-data", key);
  //           return url;
  //         })
  //       );
  //       setCarouselImages(urls);
  //     };

  //     fetchImages();
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Cannot Retrieve Images:", error);
  //   }
  // };
  // useEffect(() => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   getImageIds(userInfo?.token);
  // }, []);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = await fetchS3File(
          "earthsense-solarbot-data",
          "collection-170524_104244/thermal_1.jpg"
        );
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching image", error);
      }
    };

    fetchImage();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CustomButton />,
    prevArrow: <CustomButton />,
  };
  return (
    <>
      <div className="fixed inset-0 overflow-hidden z-50 flex justify-end">
        <div className="w-1/3 bg-gray-200 p-4 h-full transform transition-transform duration-300 translate-x-0">
          {/* Close button */}
          <button
            className="absolute top-4 z-50 right-4 text-gray-600"
            onClick={onClose}
          >
            <svg
              className="w-6 h-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
          {/* Content */}
          <div className="h-full">
            <div className="mt-8 mb-8">
              {loading ? (
                <div className="flex justify-center items-center h-1/2">
                  <div className="w-16 h-16 border-t-4 border-gray-900 rounded-full animate-spin"></div>
                </div>
              ) : carouselImages.length == 0 ? (
                // <Slider {...settings}>
                //   {carouselImages.map((image, index) => (
                // <div key={index}>
                <div>
                  <img
                    src={imageUrl}
                    // alt={`Slide ${index}`}
                    className="w-full"
                  />
                </div>
              ) : (
                //   ))}
                // </Slider>
                "No Images Availabe"
              )}
            </div>

            <pre>Class Number: {details.class_name}</pre>
            <pre>Name: {details.name}</pre>
            <pre>Minimum Temperature: {details.temperature_min}</pre>
            <pre>Maximum Temperature: {details.temperature_max}</pre>
            <pre>Temperature Difference: {details.temperature_difference}</pre>
            <pre>Timestamp: {details.timestamp}</pre>

            {/* Tabular Data
            <div>
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {tabularData.map((data, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">{data.name}</td>
                      <td className="border px-4 py-2">{data.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 transition-opacity"></div>
    </>
  );
};
const CustomButton = () => {
  return <div className="none"></div>;
};
export default SideDrawer;
