import React, { useState } from "react";
import Card from "../PlantCard/PlantCard";
import { ListFilter } from "lucide-react";
import { useNavigate } from "react-router-dom";

const items = [
  {
    image: "https://via.placeholder.com/300",
    heading: "New York",
    defects: 0,
  },
  {
    image: "https://via.placeholder.com/300",
    heading: "California",
    defects: 2,
  },
  {
    image: "https://via.placeholder.com/300",
    heading: "Florida",
    defects: 4,
  },
  {
    image: "https://via.placeholder.com/300",
    heading: "Hawaii",
    defects: 0,
  },
  // Add more items as needed
];
const Home = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [cardItems, setCardItems] = useState(items);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = cardItems.filter((item) =>
    item.heading.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const addNewCard = () => {
    const newCard = {
      image: "https://via.placeholder.com/300",
      heading: `New Plant ${cardItems.length + 1}`,
      defects: 0,
    };
    setCardItems([...cardItems, newCard]);
  };

  return (
    <div className="p-4">
      <div className="flex justify-end space-x-2 mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="p-2 border border-gray-300 rounded"
        />
        <button className="p-2 bg-gray-200 rounded">
          <ListFilter />
        </button>
        <button
          onClick={addNewCard}
          className="p-2 bg-blue-500 text-white rounded"
        >
          + New Asset
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredItems.map((item, index) => (
          <Card
            key={index}
            image={item.image}
            heading={item.heading}
            defects={item.defects}
            onClick={() => navigate(`/dashboard/${index + 1}`)}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
