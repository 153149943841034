import React, { useState, useEffect } from "react";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (user) navigate("/dashboard");
  }, [navigate]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("users/login", {
        email: email,
        password: password,
      });
      const token = response.data.token;
      const userResponse = await axios.get("users/current", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem(
        "userInfo",
        JSON.stringify({ ...userResponse.data, token })
      );
      navigate("/dashboard");
    } catch (error) {
      if (error?.response)
        setError(
          error.response.data.title + ": " + error.response.data.message
        );
      console.error("Creation failed:", error);
    }
  };

  return (
    <div
      style={{ height: "calc(100vh - 4rem)" }}
      className="flex flex-col items-center justify-center"
    >
      <div className="max-w-md w-full p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold mb-4">Sign In</h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <input
              required
              type="email"
              id="email"
              autoComplete="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-2 block w-full bg-slate-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="flex items-center mt-1 gap-x-2">
              <input
                required
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="p-2 block w-full bg-slate-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {passwordVisible ? (
                <div
                  className="px-1 py-2 bg-slate-100 rounded-md shadow-sm font-medium text-gray-700 cursor-pointer"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  Hide
                </div>
              ) : (
                <div
                  className="px-1 py-2 bg-slate-100 rounded-md shadow-sm font-medium text-gray-700 cursor-pointer"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  Show
                </div>
              )}
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign In
          </button>
        </form>
        <p className="text-sm text-gray-600 mt-4">
          Forget Password?{" "}
          <a href="/contactus" className="text-indigo-600 hover:underline">
            Contact Us
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
