import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/SignIn/signin";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import ContactUs from "./components/ContactUs/ContactUs";
import Navbar from "./components/Navbar/Navbar";
import CreateClient from "./components/CreateClient/CreateClient";
import Plant from "./components/Plant/Plant";
import HomePage from "./components/HomePage/Homepage";
import configureAWS from "./aws-config";
import Products from "./components/Products/Products";

configureAWS();

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow mt-[4rem]">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/:plantId" element={<Plant />} />
            <Route path="/createclient" element={<CreateClient />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/signin" element={<SignIn />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
